@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.leftAlign {
	.accordionHeading {
		text-align: left;
		padding-left: 10px;
	}
}

.accordionHeading {
	border: 1px solid $light-grey;
	background: $white;
	font-weight: $bold-base;
	font-size: 1.8rem;
	line-height: 2rem;
	color: $deep-green;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	padding: 10px $gutter * 2.5 8px 10px;
	text-align: left;

	&.leftIcon {
		padding: 12px 10px 12px $gutter * 2.5;

		& .icon {
			left: $gutter * 0.65;
		}
	}

	&.rightIcon {
		padding: 15px $gutter * 2.5 15px 10px;

		& .icon {
			right: 10px;
		}
	}

	@include tablet() {
		text-align: center;
		padding-left: $gutter * 2.5;
	}

	&Negative {
		background: $red;
		color: $white;

		& h4 {
			color: $white;
		}
	}

	& h4 {
		margin: 0;

		span {
			vertical-align: middle;
			display: inline-block;
			margin-right: 5px;
		}
	}

	& .icon {
		pointer-events: none;
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
	}
}

.isOpen {
	.accordionContent {
		display: block;
	}
}

.accordionContent {
	padding: $gutter / 2 $gutter / 2 $gutter / 2;
	display: none;
	background-color: $white;

	p,
	span,
	li {
		color: $deep-green !important;

		&[class*="caption"] {
			color: $grey !important;
		}
	}

	& p:last-of-type {
		margin-bottom: 0;
	}
}

.noHeadingPad{
	.accordionHeading{
		padding: 0
	}
}

.noPad {
	.accordionContent {
		padding: 0;
	}
}

.wrapper {
	margin-bottom: 10px;

	&.disabled {
		opacity: 0.3;
		cursor: default;

		& .accordionHeading {
			cursor: default;
		}
	}

	&.noMargin {
		margin-bottom: 0;
	}

	& .accordionHeading {
		border: none;
	}
}
