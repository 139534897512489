@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.benefitsBar {
	padding: 40px 0;
	text-align: center;
	color: $white;

	h2 {
		color: $white;
		font-size: 3rem;
		line-height: 3.4rem;

		@include desktop() {
			font-size: 4rem;
			line-height: 4.6rem;
		}
	}

	h3 {
		margin-bottom: 0px;
		color: $white;
	}
}

.benefitsCont {
	grid-column: 1 / span 6;
	text-align: center;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	gap: 20px;

	@include tablet() {
		grid-column: 1 / span 12;
		margin-bottom: 20px;
	}

	@include desktop() {
		grid-column: 3 / span 8;
	}

	&.benefits3 {
		.iconPoint {
			width: 250px;
			margin-bottom: 20px;

			@include tablet() {
				width: 200px !important;
				margin-bottom: 0;
			}
		}
	}

	&.verticalStack {
		flex-direction: column;

		@include tablet() {
			flex-direction: row;
		}

		.iconPoint {
			width: 100%;
			padding: 20px;

			@include tablet() {
				width: 150px;
				padding: 30px 20px;
			}
		}
	}
}

.iconPoint {
	display: inline-block;
	text-align: center;
	margin: 0px 0px 0px 0;
	color: $white;
	grid-column: span 3;
	padding: 30px 20px;
	width: 150px;
	position: relative;

	&.leftAligned {
		display: flex;
		align-items: center;
		gap: 20px;
		text-align: left;

		h3,
		h4 {
			text-align: left;
		}
	}

	@include tablet() {
		grid-column: span 2;
		margin-bottom: 0;
	}

	@include desktop() {
		margin-top: 0px;
	}

	h3,
	h4 {
		color: $white;
		text-align: center;
	}

	span {
		display: block;
		font-size: 1.6rem;
		font-weight: $bold-base;
		line-height: 2rem;
		margin-top: 5px;

		&.caption {
			font-size: 1.4rem;
			font-weight: $normal-base;
			margin-bottom: 10px;
		}
	}

	.cutOutTop,
	.cutOutBottom {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin: 0;

		&:after,
		&:before {
			content: "";
			position: absolute;
			top: -15px;
			left: -15px;
			border-radius: 100%;
			height: 30px;
			width: 30px;
		}
	}

	.cutOutTop:after {
		left: auto;
		right: -15px;
	}

	.cutOutBottom:before {
		top: auto;
		bottom: -15px;
	}

	.cutOutBottom:after {
		top: auto;
		left: auto;
		right: -15px;
		bottom: -15px;
	}
}

.ctaButton {
	margin-top: 10px;
}

// BORDER
.border-zigzag-bottom {
	position: relative;

	&:after {
		left: 0;
		content: "";
		position: absolute;
		height: 25px;
		top: calc(100% - 7px);
		width: 100%;
		background-repeat: repeat;
		background-size: 25px 30px;
		z-index: 2;
		transform: rotate(180deg);
	}
}

// BACKGROUND COLOURS
.background-deep-green {
	background-color: $deep-green;

	&.border-zigzag-bottom {
		&:after {
			background-image: url("/images/zig-zag-deep-green-i.svg");
		}
	}

	.iconPoint {
		background-color: darken($deep-green, 5%);
	}

	.cutOutTop,
	.cutOutBottom {
		&:after,
		&:before {
			background-color: $deep-green;
		}
	}
}

.background-purple {
	background-color: $purple;

	&.border-zigzag-bottom {
		&:after {
			background-image: url("/images/zig-zag-purple-i.svg");
		}
	}

	.iconPoint {
		background-color: darken($purple, 5%);
	}

	.cutOutTop,
	.cutOutBottom {
		&:after,
		&:before {
			background-color: $purple;
		}
	}
}
