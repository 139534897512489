@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.imageTextCont {
	position: relative;

	&.reverseOnMobile {
		& .content {
			margin-top: 250px;
			margin-bottom: 0px;

			@include tablet() {
				margin-top: 0;
			}
		}

		& .backgroundImage {
			bottom: auto;
			top: 0;
		}
	}
}

.buttons {
	justify-content: center;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	@include tablet() {
		justify-content: left;
	}
}

.imageText {
	grid-auto-flow: dense;
	@include desktop() {
		min-height: 320px;
	}
}

//TextOnLeft
.textOnLeft {
	.imageText {
		.content {
			@include tablet() {
				grid-column: 1 / span 6;
			}

			@include desktop() {
				grid-column: 2 / span 5;
			}
		}
	}
	.backgroundImage {
		@include tablet() {
			left: auto;
			right: 0;
		}
		&.zigzagBorder:after {
			@include tablet() {
				transform: translateY(0px) translateX(-5px) rotate(90deg);
				transform-origin: bottom left;
				right: auto;
				left: 0;
			}
		}
	}
}

.content {
	position: relative;
	grid-column: 1 / span 6;
	align-self: center;
	text-align: center;
	margin-bottom: 250px;

	@include tablet() {
		text-align: left;
		margin-bottom: 0;
		grid-column: 7 / span 6;
	}

	@include desktop() {
		grid-column: 7 / span 5;
	}

	&.videoContent {
		margin-bottom: 350px;

		@include tablet() {
			margin-bottom: 0;
		}
	}

	&:not(.headingSmall) h2 {
		font-size: 3rem;
		line-height: 3.4rem;

		@include desktop() {
			font-size: 4rem;
			line-height: 4.6rem;
		}
	}

	h1,
	h2,
	h3 {
		text-align: center;

		@include tablet() {
			text-align: left;
		}
	}

	p {
		margin-left: 0;
	}

	ul:last-child {
		margin-bottom: 0;
	}

	.contentText {
		width: 100%;
		display: block;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	.stat {
		display: grid;
		grid-template-columns: repeat(6, [col-start] 1fr);
		gap: 20px;
		margin-top: 20px;
		background-color: darken($off-white, 5%);
		padding: 15px;

		@include desktop() {
			&:first-of-type {
				margin-top: 40px;
			}
		}

		.statNumber {
			color: $yellow;
			grid-column: 1 / span 1;
		}
		.statDescription {
			text-align: left;
			grid-column: 2 / span 5;
		}
	}
}

.iconPoint {
	width: 85px;
	display: inline-block;
	text-align: center;
	margin: 0px 20px 20px 0;

	@include desktop() {
		margin-top: 10px;
	}

	span {
		display: block;
		font-size: 1.6rem;
		font-weight: $bold-base;
		line-height: 2rem;
	}
}

// SIDEICONS
.sideIcons-right-1,
.sideIcons-right-2,
.sideIcons-right-3,
.sideIcons-left-1,
.sideIcons-left-2,
.sideIcons-left-3,
.sideIcons-xmas-left-1,
.sideIcons-xmas-left-2,
.sideIcons-xmas-right-1 {
	@include desktop() {
		&:before {
			content: "";
			position: absolute;
			//transform: translateX(50%);
			left: calc(100% - 12vw);
			height: 100%;
			width: 400px;
			background-size: auto 115%;
			background-position: top center;
			background-repeat: no-repeat;
			top: 0;
			z-index: 0;
		}
	}
	@include desktopLarge() {
		&:before {
			left: auto;
			right: -180px;
		}
	}
}

.sideIcons-right-1:before {
	background-image: url("/images/side-icons-right-1.png");
	left: calc(100% - 12vw);
	@include desktopLarge() {
		left: auto;
		right: -170px;
	}
}

.sideIcons-right-2:before {
	background-image: url("/images/side-icons-right-2.png");
	left: calc(100% - 14vw);
	@include desktopLarge() {
		left: auto;
		right: -150px;
	}
}

.sideIcons-right-3:before {
	background-image: url("/images/side-icons-right-3.png");
	left: calc(100% - 14vw);
	@include desktopLarge() {
		left: auto;
		right: -150px;
	}
}

.sideIcons-left-1:before {
	background-image: url("/images/side-icons-left-1.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -180px;
	}
}

.sideIcons-left-2:before {
	background-image: url("/images/side-icons-left-2.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.sideIcons-left-3:before {
	background-image: url("/images/side-icons-left-3.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.sideIcons-xmas-left-1:before {
	background-image: url("/images/side-icons-xmas-left-1.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.sideIcons-xmas-left-2:before {
	background-image: url("/images/side-icons-xmas-left-2.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.sideIcons-xmas-right-1:before {
	background-image: url("/images/side-icons-xmas-right-1.png");
	left: calc(100% - 12vw);
	@include desktopLarge() {
		left: auto;
		right: -150px;
	}
}

.background-darker {
	background-color: darken($off-white, 5%);
}

// BACKGROUND COLOURS
.background-deep-green {
	background-color: $deep-green;
	color: $white;

	p,
	h2,
	h3,
	a:not([class*="button"]) {
		color: $white;
	}

	.backgroundImage.zigzagBorder:after {
		background-image: url("/images/zig-zag-deep-green-i.svg");
	}

	.content .stat {
		background-color: darken($deep-green, 5%);
	}
}

.background-green {
	background-color: $light-green;

	.backgroundImage.zigzagBorder:after {
		background-image: url("/images/zig-zag-green-i.svg");
	}

	.content .stat {
		background-color: darken($light-green, 5%);
	}
}

.background-purple {
	background-color: $purple;
	color: $white;

	p,
	h2,
	h3 {
		color: $white;
	}

	.backgroundImage.zigzagBorder:after {
		background-image: url("/images/zig-zag-purple-i.svg");
	}

	.content .stat {
		background-color: darken($purple, 5%);
	}
}

// IMAGE
.backgroundImage {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 250px;
	overflow: hidden;
	z-index: 0;

	@include tablet() {
		top: 0;
		bottom: auto;
		width: 43%;
		height: 100%;
	}

	&.video {
		height: 350px;

		@include tablet() {
			height: 100%;
		}
	}

	&.zigzagBorder {
		&:after {
			content: "";
			position: absolute;
			height: 25px;
			bottom: calc(100% - 18px);
			width: 100%;
			background-repeat: repeat;
			background-size: 25px 30px;
			z-index: 2;
			transform: rotate(180deg);
			background-image: url("/images/zig-zag-i.svg");

			@include tablet() {
				transform: translateY(0px) translateX(5px) rotate(270deg);
				transform-origin: bottom right;
				top: -10%;
				width: 200%;
				right: 0;
			}
		}
	}

	&.videoPaused {
		&:before {
			content: "";
			position: absolute;
			width: 100px;
			height: 100px;
			background-color: $white;
			border-radius: 100%;
			top: 50%;
			left: 50%;
			margin-left: -50px;
			margin-top: -50px;
			z-index: 3;
			background-image: url("/images/zig-zag-deep-green-i.svg");
			background-repeat: no-repeat;
			background-size: 50% auto;
			background-position: center center;
			transform: rotate(90deg);
			cursor: pointer;
			pointer-events: none;
		}
	}

	&:not(.video) > div {
		top: 50%;
		left: 50%;
		position: relative;
		transform: translate(-50%, -50%);
		height: 100%;
		width: 100%;
	}

	img,
	video {
		object-fit: cover;
		width: 100%;
		height: 100%;

		@include tablet() {
			width: 120%;
		}

		@include desktop() {
			width: 100%;
		}
	}
}
